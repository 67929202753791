<template>
  <div class="fd-h-full fd-px-5 fd-py-3">
    <span class="fd-text-lg fd-font-bold fd-text-theme-2">Features</span>
    <div class="fd-w-full fd-mt-8">
      <b-form-group
        v-slot="{ ariaDescribedby }"
        class="feature--items"
      >
        <div
          v-for="option in features"
          :key="option.id"
          class="fd-py-4 fd-px-4 fd-rounded-lg fd-flex"
          style="background-color: #F4F6F6"
        >
          <b-form-checkbox
            v-model="selected"
            :value="option.id"
            :aria-describedby="ariaDescribedby"
            name="findev"
            class="fd-cursor-pointer"
          >
            <div class="fd-flex fd-items-center">
              <img :src="option.icon" :alt="option.name" class="fd-mx-2" style="width: 24px; height: 24px; object-fit: cover; border-radius: 100%;">
              <span class="fd-block fd-ml-2 fd-text-sm fd-font-bold fd-text-theme-6">{{ option.title }}</span>
            </div>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "Feature",
  components: {BFormGroup, BFormCheckbox},
  data() {
    return {
      selected: [],
      dataCompleted: false
    }
  },
  created() {
    this.$store.dispatch('project/getTemplateFeatures', this.$route.params.templateId).then(async () => {
      await this.features.forEach(item => {
        if (item.selected) {
          this.selected.push(item.id)
        }
      })
      this.dataCompleted = true
    })
  },
  watch: {
    selected: {
      handler() {
        if (!this.dataCompleted) return
        this.updateFeatures()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      'features': 'project/templateFeatures'
    }),
    options() {
      return [
        { id: 1, name: 'Option A' },
        { id: 2, name: 'Option B' },
        { id: 3, name: 'Option C' },
        { id: 4, name: 'Option D' }
      ]
    }
  },
  methods: {
    updateFeatures() {
      this.$store.dispatch('project/updateTemplateFeatures', {
        templateId: this.$route.params.templateId,
        data: {
          features: this.selected
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .feature--items {
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}

</style>